import {ProfessionInterest} from "../dtos/profession.dto";

export enum Profession {
  ME = "ME", // Mediamatiker
  IA = "IA", // Informatiker Applikationsentwicklung
  IP = "IP", // Informatiker Plattformentwicklung
  IF = "IF", // ICT-Fachmann
  EDB = "EDB", // Entwickler digitales Business
  LO = "LO", // Logistiker
  KF = "KF", // Kaufmann
  FK = "FK", // Fachmann Kundendialog
  PM = "PM" // Polymechaniker
}

export enum ProfessionModel {
  NO = "NO", // Normallehre
  BA = "BA", // Basisausbildung
  PR = "PR" // Praktikum
}

export enum Subject {
  GERMAN = "GERMAN",
  MATHEMATICS = "MATHEMATICS",
  ENGLISH = "ENGLISH",
  FRENCH = "FRENCH",
  PHYSICS = "PHYSICS",
  CHEMISTRY = "CHEMISTRY",
  DRAWING = "DRAWING"
}

export const translateProfession = (profession: Profession): string => {
  switch (profession) {
    case Profession.ME:
      return "Mediamatiker*in EFZ";
    case Profession.IA:
      return "Informatiker*in Applikationsentwicklung EFZ";
    case Profession.IP:
      return "Informatiker*in Plattformentwicklung EFZ";
    case Profession.IF:
      return "ICT-Fachmann/-frau EFZ";
    case Profession.EDB:
      return "Entwickler*in digitales Business EFZ";
    case Profession.LO:
      return "Logistiker*in EFZ";
    case Profession.KF:
      return "Kaufmann/-frau EFZ";
    case Profession.FK:
      return "Fachmann/-frau Kundendialog EFZ";
    case Profession.PM:
      return "Polymechaniker*in EFZ";
  }
}

export const translateProfessionSimple = (profession: Profession): string => {
  switch (profession) {
    case Profession.ME:
      return "Mediamatiker*in";
    case Profession.IA:
      return "Informatiker*in Applikationsentwicklung";
    case Profession.IP:
      return "Informatiker*in Plattformentwicklung";
    case Profession.IF:
      return "ICT-Fachmann/-frau";
    case Profession.EDB:
      return "Entwickler*in digitales Business";
    case Profession.LO:
      return "Logistiker*in";
    case Profession.KF:
      return "Kaufmann/-frau";
    case Profession.FK:
      return "Fachmann/-frau Kundendialog";
    case Profession.PM:
      return "Polymechaniker*in";
  }
}

export const translateProfessionModel = (professionModel: ProfessionModel): string => {
  switch (professionModel) {
    case ProfessionModel.NO:
      return "Normallehre";
    case ProfessionModel.BA:
      return "Basisausbildung";
    case ProfessionModel.PR:
      return "Praktikum SOG";
  }
}

export const translateSubject = (subject: Subject): string => {
  switch (subject) {
    case Subject.GERMAN:
      return "Deutsch";
    case Subject.MATHEMATICS:
      return "Mathematik";
    case Subject.ENGLISH:
      return "Englisch";
    case Subject.FRENCH:
      return "Französisch";
    case Subject.PHYSICS:
      return "Physik";
    case Subject.CHEMISTRY:
      return "Chemie";
    case Subject.DRAWING:
      return "Bildnerisches Gestalten";
  }
}

export const professionInterests = (profession: Profession): Array<ProfessionInterest> => {
  switch (profession) {
    case Profession.ME:
      return [
        {icon: "photo_camera", title: "Kreativität"},
        {icon: "campaign", title: "Marketing"},
        {icon: "code", title: "Informatik"},
        {icon: "palette", title: "Gestaltung"}
      ];
    case Profession.IA:
      return [
        {icon: "code", title: "Entwicklung"},
        {icon: "terminal", title: "Wartung"},
        {icon: "lock", title: "Security"},
        {icon: "memory", title: "Hardware"}
      ];
    case Profession.IP:
      return [
        {icon: "memory", title: "Hardware"},
        {icon: "terminal", title: "Wartung"},
        {icon: "lock", title: "Security"},
        {icon: "lan", title: "Netzwerk"}
      ];
    case Profession.LO:
      return [
        {icon: "warehouse", title: "Lager"},
        {icon: "conveyor_belt", title: "Distribution"},
        {icon: "local_shipping", title: "Verkehr"},
        {icon: "inventory", title: "Verwaltung"}
      ];
    case Profession.EDB:
      return [
        {icon: "rebase_edit", title: "Koordination"},
        {icon: "group", title: "Kundenkontakt"},
        {icon: "account_tree", title: "Prozesse"},
        {icon: "query_stats", title: "Analytics"}
      ];
    case Profession.IF:
      return [
        {icon: "support_agent", title: "Support"},
        {icon: "browser_updated", title: "Installationen"},
        {icon: "build", title: "Wartung"},
        {icon: "school", title: "Schulungen"}
      ];
    case Profession.KF:
      return [
        {icon: "bar_chart", title: "Organisationsformen"},
        {icon: "groups", title: "Vernetztes Umfeld"},
        {icon: "account_tree", title: "Koordinierte Prozesse"},
        {icon: "devices", title: "Digitale Arbeitswelt"}
      ];
    case Profession.FK:
      return [
        {icon: "group", title: "Kundenkontakt"},
        {icon: "support_agent", title: "Beratung"},
        {icon: "language", title: "Sprachen"},
        {icon: "devices", title: "Digitale Arbeitswelt"}
      ];
    case Profession.PM:
      return [
        {icon: "build", title: "Technik"},
        {icon: "width", title: "Räumliches Vorstellungsvermögen"},
        {icon: "schedule", title: "Ausdauer"},
        {icon: "group", title: "Teamfähigkeit"}
      ];
  }
}

export const professionDescription = (profession: Profession): string => {
  switch (profession) {
    case Profession.ME:
      return "Die Kenntnisse in Gestaltung, Informatik, Multimedia und Marketing machen Mediamatiker*innen zu Generalisten im Umgang mit Medien. Sie erstellen Fotos, Videos, Texte, Werbemittel und Internet-Beiträge und veröffentlichen diese auf den entsprechenden Plattformen.";
    case Profession.IP:
      return "Informatiker*innen der Plattformentwicklung  bauen Informatiksysteme, wie Arbeitsplätze und Server auf und warten diese. Sie verbinden Hard- und Software miteinander und sorgen dafür, dass alle Systeme funktionieren.";
    case Profession.IA:
      return "Informatiker*innen der Applikationsentwicklung programmieren Software für interne oder externe Tools oder arbeiten am Frontend und Backend einer Website. Sie arbeiten im Team, um die beste Lösung für den Auftraggeber zu finden.";
    case Profession.KF:
      return "Als Kaufmann/-Frau erledigst du administrative Arbeiten aller Art. Mit dem Bildungsmarktplatz kannst du einen Einblick in viele Abteilungen erhalten und vielfältige Aufgaben erledigen.";
    case Profession.IF:
      return "ICT-Fachpersonen installieren und konfigurieren Benutzerendgeräte, unterstützen Benutzer bei technischen Problemen und stellen den Betrieb in der Netzinfrastrukturen sicher.";
    case Profession.FK:
      return "Fachleute Kundendialog beraten und betreuend die Kundschaft professionell zu verschiedenen Produkten und Anfragen. Sie erkennen Kundenbedürfnisse und gehen darauf ein. Sie führen Verkaufsgespräche und leiten sie an die zuständige Stelle weiter.";
    case Profession.LO:
      return "Logistiker/innen transportieren, lagern und verteilen Güter. Sie nehmen Waren von Lieferanten entgegen und sorgen, dafür, dass sie richtig gelagert wird, um einen möglichst effizienten Warenfluss zu erzeugen.";
    case Profession.EDB:
      return "Entwickler*innen digitales Business verstehen Geschäftsprozesse und optimieren diese mit Datenanalysen. Sie sind im ständigen Austausch mit den Fachpersonen aus verschiedenen Bereichen und der Kundschaft.";
    case Profession.PM:
      return "Polymechanikerinnen und Polymechaniker fertigen Werkzeuge, Bauteile und Produktionsanlagen. Diese stellen sie aus verschiedenen Materialien, wie Stahl, Chromstahl, Aluminium und Kunst- oder Verbundstoffen her. Sie bedienen verschiedene Maschinen, darunter auch computergesteuerte, überwachen die Produktion, nehmen Anlagen in Betrieb und warten sie."
  }
}
