import "./nav.style.scss";
import NavItem from "./item/nav-item.component";
import useWindowDimensions from "../../../hooks/window-dimension.hook";
import Drawer from "../drawer/drawer.component";
import {useState} from "react";
import NavDrawer from "./drawer/nav-drawer.component";
import {Color} from "../../../enums/color.enum";
import {Page} from "./nav.type";

const Nav = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedPage, setSelectedPage] = useState<Page | null>(null);
    const {width} = useWindowDimensions();

    const pages: Array<Page> = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "News",
            path: "/news"
        },
        {
            name: "Ausbildungen",
            path: "/ausbildungen"
        },
        {
            name: "Veranstaltungen",
            path: "/veranstaltungen"
        },
        {
            name: "Über uns",
            path: "/ueber-uns",
            subPages: [
                {
                    name: "Bildungsmarktplatz",
                    path: "/ueber-uns/bildungsmarktplatz"
                },
                {
                    name: "Vision",
                    path: "/ueber-uns/vision"
                },
                {
                    name: "Interviews",
                    path: "/ueber-uns/testimonials"
                },
                {
                    name: "LernAcademy",
                    path: "/ueber-uns/lernacademy"
                }
            ]
        },
        {
            name: "Ausbildungsplätze",
            path: "/ausbildungsplaetze",
            subPages: [
                {
                    name: "Online-Ausschreibungen",
                    path: "/ausbildungsplaetze/online-ausschreibungen"
                },
                {
                    name: "Lehr- und Praktikumstellen",
                    path: "/ausbildungsplaetze/lehr-und-praktikumstellen"
                },
                {
                    name: "Bewerbungsinformationen",
                    path: "/ausbildungsplaetze/bewerbungsinformationen"
                },
                {
                    name: "Schnuppern bei BRACK.CH",
                    path: "/ausbildungsplaetze/schnuppern-bei-brack"
                }
            ]
        }
    ]

    return (
        <nav className="nav">
            {
                width < 950 ? (
                        <Drawer
                            open={open}
                            updateOpen={() => setOpen(!open)}
                            selectedPage={selectedPage}
                            updateSelectedPage={() => setSelectedPage(null)}
                            color={Color.BASE_INVERTED}
                        >
                            <NavDrawer
                                pages={pages}
                                updateOpen={() => setOpen(false)}
                                selectedPage={selectedPage}
                                updateSelectedPage={(page: Page) => setSelectedPage(page)}
                            />
                        </Drawer>
                    )
                    : (
                        pages.map((page, key) => <NavItem page={page} key={key}/>)
                    )
            }
        </nav>
    )
};

export default Nav;
