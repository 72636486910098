import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import ProfessionCard from "../../components/complex/profession/card/profession-card.component";
import {Profession, professionDescription, ProfessionModel, translateProfession} from "../../enums/profession.enum";
import {Position} from "../../enums/position.enum";
import imageME from "../../images/profession/ME.jpg";
import imageIA from "../../images/profession/IA.jpg";
import imageIP from "../../images/profession/IP.jpg";
import imageIF from "../../images/profession/IF.jpg";
import imageEDB from "../../images/profession/EDB.jpg";
import imageLO from "../../images/profession/LO.jpg";
import imageKF from "../../images/profession/KF.jpg";
import imageFK from "../../images/profession/FK.jpg";
import imagePM from "../../images/profession/PM.jpg";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import Chip from "../../components/base/chip/chip.component";
import {useNavigate} from "react-router-dom";

const ProfessionsPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Ausbildungen</h1>
                <h5>
                    Seit 1999 liegt uns bei BRACK.CH die Ausbildung von Jugendlichen sehr am Herzen. An den drei
                    Standorten Mägenwil, Willisau und Bussigny werden die Lernenden und Praktikanten in
                    ihren Traumberufen ausgebildet und auf die bevorstehende Arbeitswelt vorbereitet.
                </h5>
                <LayoutSequence direction={LayoutSequenceDirection.ROW}>
                    <Chip action={() => navigate(Profession.ME)}>{translateProfession(Profession.ME)}</Chip>
                    <Chip action={() => navigate(Profession.IA)}>{translateProfession(Profession.IA)}</Chip>
                    <Chip action={() => navigate(Profession.IP)}>{translateProfession(Profession.IP)}</Chip>
                    <Chip action={() => navigate(Profession.IF)}>{translateProfession(Profession.IF)}</Chip>
                    <Chip action={() => navigate(Profession.EDB)}>{translateProfession(Profession.EDB)}</Chip>
                    <Chip action={() => navigate(Profession.LO)}>{translateProfession(Profession.LO)}</Chip>
                    <Chip action={() => navigate(Profession.PM)}>{translateProfession(Profession.PM)}</Chip>
                    <Chip action={() => navigate(Profession.KF)}>{translateProfession(Profession.KF)}</Chip>
                    <Chip action={() => navigate(Profession.FK)}>{translateProfession(Profession.FK)}</Chip>
                    <Chip action={() => navigate(ProfessionModel.PR)}>{"Praktika"}</Chip>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <ProfessionCard profession={Profession.ME}
                                description={professionDescription(Profession.ME)}
                                image={imageME}
                                imagePosition={Position.RIGHT}/>
                <ProfessionCard profession={Profession.IA}
                                description={professionDescription(Profession.IA)}
                                image={imageIA}
                                imagePosition={Position.LEFT}/>
                <ProfessionCard profession={Profession.IP}
                                description={professionDescription(Profession.IP)}
                                image={imageIP}
                                imagePosition={Position.RIGHT}/>
                <ProfessionCard profession={Profession.IF}
                                description={professionDescription(Profession.IF)}
                                image={imageIF}
                                imagePosition={Position.LEFT}/>
                <ProfessionCard profession={Profession.EDB}
                                description={professionDescription(Profession.EDB)}
                                image={imageEDB}
                                imagePosition={Position.RIGHT}/>
                <ProfessionCard profession={Profession.LO}
                                description={professionDescription(Profession.LO)}
                                image={imageLO}
                                imagePosition={Position.LEFT}/>
                <ProfessionCard profession={Profession.PM}
                                description={professionDescription(Profession.PM)}
                                image={imagePM}
                                imagePosition={Position.RIGHT}/>
                <ProfessionCard profession={Profession.KF}
                                description={professionDescription(Profession.KF)}
                                image={imageKF}
                                imagePosition={Position.LEFT}/>
                <ProfessionCard profession={Profession.FK}
                                description={professionDescription(Profession.FK)}
                                image={imageFK}
                                imagePosition={Position.RIGHT}/>
            </LayoutSection>
        </>
    )
}

export default ProfessionsPage;
