import {Route, Routes} from "react-router-dom";
import Header from "./components/complex/header/header.component";
import Footer from "./components/complex/footer/footer.component";
import NotFoundPage from "./pages/not-found/not-found.page";
import HomePage from "./pages/home/home.page";
import "./app.style.scss"
import BlogsPage from "./pages/blogs/blogs.page";
import ProfessionsPage from "./pages/professions/professions.page";
import EventsPage from "./pages/events/events.page";
import AboutUsPage from "./pages/about-us/about-us.page";
import ApplyPage from "./pages/training-places/apply/apply.page";
import BlogPage from "./pages/blogs/blog.page";
import ModelsPage from "./pages/professions/models.page";
import ProfessionPage from "./pages/professions/profession.page";
import EventPage from "./pages/events/event.page";
import ContactPage from "./pages/about-us/contact/contact.page";
import ScrollToTop from "./components/complex/scroll-to-top/scroll-to-top.component";
import Toasty from "./components/complex/toasty/toasty.component";
import InfoPage from "./pages/training-places/info/info.page";
import TestimonialsPage from "./pages/about-us/testimonials/testimonials.page";
import TestimonialPage from "./pages/about-us/testimonials/testimonial.page";
import MarketPlacePage from "./pages/about-us/market-place/market-place.page";
import VisionPage from "./pages/about-us/vision/vision.page";
import LernAcademyPage from "./pages/about-us/lernacademy/lern-academy.page";
import SchnuppernPage from "./pages/training-places/schnuppern/schnuppern.page";
import VacanciesPage from "./pages/training-places/vacancies/vacancies.page";
import TrainingPlacesPage from "./pages/training-places/training-places.page";
import InternshipPage from "./pages/professions/internship.page";

const App = () => {
    return (
        <>
            <Header/>
            <ScrollToTop/>
            <main className="main">
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/news" element={<BlogsPage/>}/>
                    <Route path="/news/:id" element={<BlogPage/>}/>
                    <Route path="/ausbildungen" element={<ProfessionsPage/>}/>
                    <Route path="/ausbildungen/PR" element={<InternshipPage/>}/>
                    <Route path="/ausbildungen/:profession" element={<ModelsPage/>}/>
                    <Route path="/ausbildungen/:profession/:model" element={<ProfessionPage/>}/>
                    <Route path="/veranstaltungen" element={<EventsPage/>}/>
                    <Route path="/veranstaltungen/:id" element={<EventPage/>}/>
                    <Route path="/ueber-uns" element={<AboutUsPage/>}/>
                    <Route path="/ueber-uns/bildungsmarktplatz" element={<MarketPlacePage/>}/>
                    <Route path="/ueber-uns/vision" element={<VisionPage/>}/>
                    <Route path="/ueber-uns/testimonials" element={<TestimonialsPage/>}/>
                    <Route path="/ueber-uns/testimonials/:id" element={<TestimonialPage/>}/>
                    <Route path="/ueber-uns/lernacademy" element={<LernAcademyPage/>}/>
                    <Route path="/ueber-uns/kontakt" element={<ContactPage/>}/>
                    <Route path="/ausbildungsplaetze" element={<TrainingPlacesPage/>}/>
                    <Route path="/ausbildungsplaetze/online-ausschreibungen" element={<ApplyPage/>}/>
                    <Route path="/ausbildungsplaetze/lehr-und-praktikumstellen" element={<VacanciesPage/>}/>
                    <Route path="/ausbildungsplaetze/bewerbungsinformationen" element={<InfoPage/>}/>
                    <Route path="/ausbildungsplaetze/schnuppern-bei-brack" element={<SchnuppernPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </main>
            <Toasty/>
            <Footer/>
        </>
    );
}

export default App;
