import {Color} from "../../../enums/color.enum";
import LayoutGrid from "../../../components/base/layout/grid/layout-grid.component";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import "./vision.style.scss"
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const VisionPage = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        const element = document.getElementById(hash);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1 id="vision">Vision</h1>
                <h5>
                    Als eines der führenden ICT-Ausbildungsunternehmen im Kanton AG engagieren wir uns stark, und
                    nachhaltig für die Jugend, unseren Nachwuchs und die späteren ICT-Fachkräfte. Wir entwickeln sie zu
                    qualifizierten, verantwortungsvollen Berufsleuten mit Perspektiven.
                </h5>
                <h5>Dies erreichen wir durch</h5>
                <ul>
                    <li>Wegweisende und praxisorientierte Aus- und Weiterbildung</li>
                    <li>Zukunftsorientierte und effektive Lehr- und Lernformen</li>
                    <li>Partnerschaftliche Zusammenarbeit mit anderen Anspruchsgruppen</li>
                </ul>
                <h1 id="mission">Mission</h1>
                <h5>
                    Wir fördern ihre Kompetenzen und unterstützen unsere Lernenden mit viel Leidenschaft und Herzblut.
                    Unsere dynamische, ganzheitliche Ausbildung verbindet die Theorie mit der Praxis und fördert die
                    Persönlichkeitsentwicklung der Jugendlichen. Wir geben unseren Lernenden viel Raum und schenken
                    ihnen Vertrauen, damit sie sich und ihre Fähigkeiten entfalten und ausschöpfen können.
                </h5>
                <h5>
                    Frei nach dem Motto
                    <blockquote>„Jugendliche ausbilden, fordern und fördern!“</blockquote>
                </h5>
                <h5>
                    Mit unseren neuen Ausbildungskonzept dem Bildungsmarktplatz unterstützen wir die
                    nachhaltige Entwicklung von Jugendlichen, Unternehmen, Institutionen und der unserer Gesellschaft.
                </h5>
                <h1 id="werte">Werte</h1>
                <LayoutGrid desktop={3} tablet={2} mobile={1}>
                    <div className="grid-item">
                        <h4>Verantwortung</h4>
                        <p>
                            Durch die Übertragung von Eigenverantwortung und durch die Gewährung von unterschiedlichen
                            Handlungsspielraum animieren wir unsere Auszubildenden sich beruflich, wie auch privat zu
                            entfalten.
                        </p>
                    </div>
                    <div className="grid-item">
                        <h4>Zukunftssicherheit</h4>
                        <p>
                            Wir interessieren und engagieren uns auch für die Personalentwicklung nach der Ausbildung.
                            Mit unserer offenen Kultur schaffen wir ehrliche Transparenz und Sicherheit. Wir sind
                            neugierig und haben den Mut auch Veränderungen voranzutreiben.
                        </p>
                    </div>
                    <div className="grid-item">
                        <h4>Innovation</h4>
                        <p>
                            Durch hohe Flexibilität und grosse Innovationsbereitschaft erlangen unsere Lernenden das
                            nötige Rüstzeug, damit diese in einer komplexen und vernetzten Welt, die technologischen,
                            kulturellen, wirtschaftlichen und demografischen Veränderungen unterworfen ist, bestehen
                            können.
                        </p>
                    </div>
                    <div className="grid-item">
                        <h4>Zuverlässigkeit</h4>
                        <p>
                            Wir streben ein partnerschaftliches und langfristiges Arbeitsverhältnis zu unserer Jugend,
                            dass von Vertrauen, Respekt und Verlässlichkeit geprägt ist.
                        </p>
                    </div>
                    <div className="grid-item">
                        <h4>Ausbildungsqualität</h4>
                        <p>
                            Der regelmässige disziplinarische wie auch fachliche Austausch mit unserem internen
                            Bildungsteam und den externen Bildungsinstituten wird bei uns grossgeschrieben.
                        </p>
                    </div>
                    <div className="grid-item">
                        <h4>Partnerschaft</h4>
                        <p>
                            Dank unserem grossen Netzwerk mit Kunden, Lieferanten, Institutionen und Behörden
                            ermöglichen wir gewinnbringende Synergien für alle Beteiligten und profilieren uns als
                            kompetenter Partner in allen Aspekten des Bildungswesens.
                        </p>
                    </div>
                    <div className="grid-item">
                        <h4>Gemeinsam zum Ziel!</h4>
                        <p>
                            Unsere partnerschaftliche Zusammenarbeit mit allen Beteiligten, basiert auf Wertschätzung,
                            Vertrauen und gegenseitiger Unterstützung. Wir teilen unsere Erfahrungen und unser Wissen
                            klar, offen, aktiv und transparent.
                        </p>
                    </div>
                </LayoutGrid>
            </LayoutSection>
        </>
    )
}

export default VisionPage;
