import {Color} from "../../../enums/color.enum";
import AboutUsMarktplatz from "../../../components/complex/about-us/marktplatz/about-us-marktplatz.component";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";

const MarketPlacePage = () => {
    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Bildungsmarktplatz</h1>
                <h5>
                    Unser neues Ausbildungskonzept der "Bildungsmarktplatz", basiert auf einer eigens programmierter
                    ONLINE-Plattform für den internen wie auch für den externen (bei Partnerfirmen) Einsatz von
                    Lernenden. Mitarbeiter unserer Firma und bildungsverantwortliche Personen aus anderen Firmen,
                    bieten interessante Einsätze (Aufgaben, Tätigkeiten, Projekte usw.) auf dem Bildungsmarktplatz für
                    Auszubildende an. Unsere Lernenden können sich laufend auf unsere ausgeschriebenen Projekte (mit
                    einem Lebenslauf und einem Motivationsschreiben) bewerben. Lernende von Partnerfirmen bewerben sich
                    über die bildungsverantwortliche Person für externe Einsätze in anderen Ausbildungsbetrieben. Einem
                    Lehrlingsaustausch steht so nichts mehr im Wege.
                </h5>
                <h5>
                    Dir bleibt mit diesem modernen und dynamischen Ausbildungsmodell viel Freiraum beim Erreichen deiner
                    notwendigen Handlungskompetenzen (Leistungsziele). Du kannst und darfst deine Ausbildung persönlich
                    mitgestalten!
                </h5>
                <AboutUsMarktplatz/>
                <h3>Berufsbildner (Lernbegleitung)</h3>
                <p>
                    Der Berufsbildner ist deine erste Ansprechperson während deiner ganzen Ausbildung. Er begleitet und
                    unterstützt dich disziplinarisch in allen Belangen (betrieblich, schulisch und privat).
                </p>
                <h3>Praxisbildner</h3>
                <p>
                    Jeder Mitarbeiter kann die Rolle eines Praxisbildners übernehmen. Als Praxisbildner, bietet man
                    auf dem Bildungsmarktplatz ein Projekt für einen Lernenden an und unterstützt den Lernenden fachlich
                    dabei.
                </p>
                <h3>Homeoffice</h3>
                <p>
                    Auch wir Lernende haben die Möglichkeit nach Absprache mit unserem Berufsbildner (Lernbegleitung)
                    von Zuhause aus zu arbeiten.
                </p>
            </LayoutSection>
        </>
    )
}

export default MarketPlacePage;
